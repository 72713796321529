import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  Image,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import { StackScreenProps } from "@react-navigation/stack";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import { FooterGrid } from "../components/Footer";
const auth = getAuth();

const SignUp: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    if (Platform.OS === "web") {
      if (showSidebar) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    }
  }, [showSidebar]);

  const handleToggleSidebar = () => setShowSidebar((prevState) => !prevState);
  const [value, setValue] = React.useState({
    email: "",
    password: "",
    error: "",
  });

  async function signUp() {
    if (value.email === "" || value.password === "") {
      setValue({
        ...value,
        error: "Email and password are mandatory.",
      });
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, value.email, value.password);
      navigation.navigate("Sign In");
    } catch (error: any) {
      setValue({
        ...value,
        error: error.message,
      });
    }
  }

  return (
    <>
      {showSidebar && (
        <Sidebar
          navigation={navigation}
          toggleSidebar={handleToggleSidebar}
          sidebarVisible={showSidebar}
        />
      )}
      <Navigation
        navigation={navigation}
        toggleSidebar={handleToggleSidebar}
        sidebarVisible={showSidebar}
      />
      <SafeAreaView style={styles.container}>
        <View style={styles.mainForm}>
          <View>
            <Image
              source={require("../assets/ph_logo.png")}
              style={styles.logo}
            />
            <View>
              <Text style={styles.signUpText}>Sign Up</Text>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabels}>Enter your Email*</Text>
            <View style={styles.inputWrapper}>
              <Icon name="person" size={16} style={styles.icon} />
              <TextInput
                style={styles.inputControl}
                placeholder="Email"
                value={value.email}
                onChangeText={(text) => setValue({ ...value, email: text })}
              />
            </View>
          </View>

          <View style={styles.inputContainer}>
            <Text style={styles.inputLabels}>Create a Password*</Text>
            <View style={styles.inputWrapper}>
              <Icon name="lock" size={16} style={styles.icon} />
              <TextInput
                style={styles.inputControl}
                placeholder="Password"
                value={value.password}
                onChangeText={(text) => setValue({ ...value, password: text })}
                secureTextEntry={true}
              />
            </View>
          </View>
          <TouchableOpacity style={styles.signUpBtnContainer} onPress={signUp}>
            <Text style={styles.signUpBtn}>Sign Up</Text>
          </TouchableOpacity>
        </View>
        {!!value.error && (
          <View style={styles.error}>
            <Text>{value.error}</Text>
          </View>
        )}

        <View style={styles.signInBtnContainer}>
          <Text style={styles.signInBtnTitle}> Already have an account? </Text>
          <TouchableOpacity onPress={() => navigation.navigate("Sign In")}>
            <Text style={styles.link}>Sign In</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
      <FooterGrid />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 200,
    paddingBottom: 100,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  signUpText: {
    fontSize: 30,
    fontWeight: "700",
    fontFamily: "Montserrat-Bold",
    textAlign: "center",
    marginBottom: 20,
  },
  signInBtnContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 12,
    backgroundColor: "#000",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 30,
  },
  signInBtnTitle: {
    fontFamily: "Montserrat-Regular",
    borderRadius: 30,
    fontSize: 14,
    color: "#fff",
    lineHeight: 20,
    fontWeight: "700",
    marginRight: 10,
  },
  link: {
    color: "#2f81f7",
    textDecorationLine: "none",
    fontFamily: "Montserrat-Bold",
  },
  mainForm: {
    width: "80%",
    maxWidth: 350,
    backgroundColor: "#e1e4e7",
    padding: 20,
    borderRadius: 12,
    marginTop: 16,
    marginBottom: 10,
  },
  inputContainer: {
    marginBottom: 10,
  },
  inputWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: "#000",
    marginRight: 14,
  },
  inputControl: {
    width: "80%",
    backgroundColor: "#fff",
    borderRadius: 30,
    padding: 3,
    paddingVertical: 10,
    paddingLeft: 6,
    fontSize: 16,
    fontFamily: "Montserrat-Regular",
  },
  signUpBtnContainer: {
    alignItems: "center",
    width: "100%",
    marginTop: 12,
  },
  signUpBtn: {
    width: "80%",
    borderRadius: 30,
    fontSize: 14,
    color: "#fff",
    backgroundColor: "#000",
    fontFamily: "Montserrat-Bold",
    lineHeight: 20,
    fontWeight: "700",
    textAlign: "center",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  inputLabels: {
    fontFamily: "Montserrat-Regular",
    lineHeight: 28,
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    marginBottom: 5,
  },
  error: {
    marginTop: 10,
    padding: 10,
    color: "#fff",
    backgroundColor: "#ed4e50",
    borderRadius: 30,
  },
  logo: {
    marginTop: 10,
    width: 48,
    height: 48,
    margin: "auto",
    marginBottom: 20,
  },
});

export default SignUp;
