import React, { useRef, useState, useEffect } from "react";
import {
  StyleSheet,
  Dimensions,
  Platform,
  ScrollView,
  View,
  TouchableOpacity,
} from "react-native";
import { ResizeMode, Video } from "expo-av";
import { StackScreenProps } from "@react-navigation/stack";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Marketing from "../components/Marketing";
import Promotional from "../components/Promotional";
import Footer from "../components/Footer";
import { useAuthentication } from "../utils/hooks/useAuthentication";
import Icon from "react-native-vector-icons/MaterialIcons";

const height = Dimensions.get("window").height;

const Splash: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { user } = useAuthentication();
  useEffect(() => {
    if (Platform.OS === "web") {
      if (showSidebar) document.body.style.overflow = "scroll";
      else document.body.style.overflow = "auto";
    }
  }, [showSidebar]);

  const handleToggleSidebar = () => setShowSidebar((prevState) => !prevState);

  return (
    <>
      <Navigation
        navigation={navigation}
        toggleSidebar={handleToggleSidebar}
        sidebarVisible={showSidebar}
      />
      {showSidebar && (
        <Sidebar
          navigation={navigation}
          toggleSidebar={handleToggleSidebar}
          sidebarVisible={showSidebar}
          isLoggedIn={user}
        />
      )}
      <ScrollView
        scrollEnabled={!showSidebar}
        style={{ overflow: "hidden", zIndex: -1 }}
      >
        <View style={[styles.container, showSidebar && { overflow: "hidden" }]}>
          <Hero />
          <Marketing />
          <Promotional navigation={navigation} />
          <Footer navigation={navigation} />
        </View>
      </ScrollView>
    </>
  );
};

const Hero = () => {
  const videoRef = useRef<Video>(null);
  const [isMute, setIsMute] = useState(true);
  const [playing, setPlaying] = useState(true);

  const handlePlayPause = async () => {
    if (videoRef.current) {
      if (playing) {
        await videoRef.current.pauseAsync(); // Pause
      } else {
        await videoRef.current.playAsync(); // Play
      }
      setPlaying(!playing); // Toggle the state
    }
  };

  useEffect(() => {
    if (Platform.OS === "web" && videoRef.current) {
      const videoEl = videoRef.current;
      const playPromise = videoEl.loadAsync(require("../assets/video.mp4"));

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            videoEl.playAsync();
          })
          .catch((error) => {
            console.log("Failed to play video:", error);
          });
      }
    }
  }, []);

  return (
    <View style={styles.heroContainer}>
      <Video
        ref={videoRef}
        source={require("../assets/video.mp4")}
        onReadyForDisplay={(videoData) => {
          if (Platform.OS === "web") {
            (videoData as any).target.style.position = "initial";
          }
        }}
        style={[styles.video, { opacity: 1 }]}
        shouldPlay={Platform.OS !== "web"}
        isLooping
        resizeMode={ResizeMode.COVER}
        isMuted={isMute}
      />
      {Platform.OS === "web" && isMute && (
        <>
          <View style={styles.playButtonContainer}>
            <TouchableOpacity onPress={handlePlayPause}>
              <View style={styles.playButton}>
                {playing ? (
                  <Icon name="pause" size={16} color="#333" />
                ) : (
                  <Icon name="play-arrow" size={16} color="#333" />
                )}
              </View>
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  heroContainer: {
    position: "relative",
    zIndex: -1,
    backgroundColor: "#fff",
    marginTop: Platform.OS === "web" ? -10 : -100,
  },
  video: {
    width: "100%",
    height: Platform.OS === "web" ? "auto" : height * 0.6,
    right: 0,
    overflow: "hidden",
    bottom: 0,
    top: 0,
  },
  playButtonContainer: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 1,
    backgroundColor: "#ffffff80",
    padding: 1,
    borderRadius: 30,
  },
  playButton: {
    color: "#333",
    fontWeight: "bold",
    fontSize: 12,
  },
});

export default Splash;
