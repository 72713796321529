import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import Offers from "./Offers";
import * as Font from "expo-font";
import React, { useState, useEffect } from "react";

const windowWidth = Dimensions.get("window").width;

function Services() {
  const [currentMonth, setCurrentMonth] = useState("");

  useEffect(() => {
    async function loadFont() {
      await Font.loadAsync({
        "Montserrat-Regular": {
          uri: "https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.1/demo/css/montserrat-regular.ttf",
        },
        // Add other variants if needed
        "Montserrat-Bold": {
          uri: "https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.1/demo/css/montserrat-bold.ttf",
        },
        "Montserrat-Alternates": {
          uri: "https://db.onlinewebfonts.com/t/73e177b8f64802141601085dd1c1fc66.ttf",
        },
        "Montserrat-Alternates-Medium": {
          uri: "https://db.onlinewebfonts.com/t/701a65adb3bd29c56e9443330538b467.ttf",
        },
      });
    }
    loadFont();

    const MONTHS = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date();
    const currentMonthIndex = date.getMonth();
    const currentMonthName = MONTHS[currentMonthIndex];
    setCurrentMonth(currentMonthName);
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Hello {currentMonth}!</Text>
      <Text style={styles.subheader}>
        Experience Innovation and Growth! Explore our dynamic social media
        essentials this month, designed to elevate your online presence. Stay
        ahead with cutting-edge offerings that engage your audience effectively.
      </Text>
      <Offers />
    </View>
  );
}

export default Services;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#000",
    color: "#fff",
    alignItems: "center",
    paddingVertical: Platform.OS === "web" ? "20px" : "10px",
    zIndex: -1,
  },
  title: {
    color: "#fff",
    fontSize: Platform.OS === "web" ? 36 : 18,
    lineHeight: 40,
    paddingBottom: 10,
    fontWeight: "700",
    fontFamily: "Montserrat-Bold",
  },
  subheader: {
    color: "#fff",
    fontSize: Platform.OS === "web" ? 20 : 14,
    paddingBottom: 10,
    lineHeight: 28,
    fontWeight: "400",
    textAlign: "center",
    maxWidth: Platform.OS === "web" ? (windowWidth > 700 ? "70%" : '90%') : "40%",
    fontFamily: "Montserrat-Regular",
  },
});
