import React from "react";
import { Dimensions, View, Text, StyleSheet, TouchableOpacity, Linking } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

const windowWidth = Dimensions.get("window").width;

function Support() {
  
  const columnHeaders = [
    {
      header: "Support",
      icon: "phone",
    },
    {
      header: "Start your career",
      icon: "briefcase",
    },
  ];

  const gridData = [
    [
      "Do you need to speak with someone? We’re here to assist!",
      "We’re hiring and looking for talented people to join our amazing team. Are you the one?",
    ],
    ["Schedule a one-on-one support call. ", ""],
    ["We’re available Monday-Friday: 5:00 AM - 5:00 PM PST", ""],
    ["", ""], // Empty row
    ["", ""], // Empty row
    ["", ""], // Empty row
  ];

  const handleSupportButtonPress = () => {
    Linking.openURL("tel:+15033741908");
  };

  const handleCareerButtonPress = () => {
    Linking.openURL("https://www.100kmind.com/careers");
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {columnHeaders.map((column, columnIndex) => (
          <View key={columnIndex} style={styles.column}>
            <View style={styles.headerContainer}>
              {column.icon === "phone" && (
                <Icon
                  name={column.icon}
                  size={18}
                  color="#000"
                  style={styles.icon}
                />
              )}
              {column.icon === "briefcase" && (
                <Icon
                  name={column.icon}
                  size={18}
                  color="#000"
                  style={styles.icon}
                />
              )}
              <Text style={styles.header}>{column.header}</Text>
            </View>
            {gridData.map((row, rowIndex) => (
              <View key={rowIndex} style={styles.gridTextContainer}>
                <Text style={styles.gridText}>{row[columnIndex]}</Text>
              </View>
            ))}
            <TouchableOpacity
              style={styles.button}
              onPress={
                columnIndex === 0
                  ? handleSupportButtonPress
                  : handleCareerButtonPress
              }
            >
              <Text style={styles.buttonText}>
                {columnIndex === 0 ? "SCHEDULE A SUPPORT CALL" : "APPLY NOW"}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    </View>
  );
}


export default Support;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 30,
    paddingTop: windowWidth > 600 ? 80 : 20,
    paddingBottom: windowWidth > 600 ? 120 : 0,
    gapHorizontal: 20,
  },
  gridTextContainer: {
    paddingHorizontal: 10, // Add horizontal padding
    paddingVertical: 5, // Add vertical padding
  },
  gridText: {
    color: "#000",
    fontSize: 16,
    fontFamily: "Montserrat-Regular",
    fontWeight: "400",
    lineHeight: 24,
    textAlign: "center",
  },
  row: {
    flexDirection: windowWidth > 600 ? "row" : "column",
    justifyContent: "space-evenly",
    marginBottom: 10,
    gap: windowWidth > 600 ? 50 : 50,

  },
  column: {
    flex: 1,
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
    marginBottom: 10,
  },
  icon: {
    marginRight: 5,
  },
  header: {
    color: "#000",
    fontSize: 21,
    lineHeight: 29,
    fontWeight: "600",
    textAlign: "center",
    fontFamily: "Montserrat-Bold",
    // marginBottom: 10,
    textTransform: "uppercase",
  },
  text: {
    color: "#000",
    fontSize: 16,
    fontFamily: "Montserrat-Regular",
    fontWeight: "400",
    lineHeight: 19,
    textAlign: "center",
  },
  buttonRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#000",
    borderRadius: 30,
    padding: 15,
    paddingHorizontal: 20,
    marginLeft: 10,
  },
  buttonText: {
    color: "#fff",
    fontSize: 14,
    lineHeight: 17,
    textAlign: "center",
    fontWeight: "700",
    fontFamily: "Montserrat-Bold",
  },
});
