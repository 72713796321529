import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Linking,
  TouchableOpacity,
} from "react-native";
import { useAuthentication } from "../utils/hooks/useAuthentication";
import { Button } from "@rneui/themed";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import UpdateMonday from "./UpdateMonday";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import { storage } from "../utils/storage";
import { ActivityIndicator } from "react-native";

import Icon from "react-native-vector-icons/MaterialIcons";
const auth = getAuth();
const functions = getFunctions();
const checkEmail = httpsCallable(functions, "function-1");
const createAccount = httpsCallable(functions, "createAccount");
const getDropBoxUrl = httpsCallable(functions, "getDropboxUrl");
const getUserData = httpsCallable(functions, "getMondayUserData");

const Account: React.FC = ({ navigation }: any) => {
  let { user } = useAuthentication();
  const isLoggedIn = !!user;
  const [sharingInprogress, setSharingInProgress] = useState(false); // Sharing Dropbox Link
  const [linkingInProgress, setLinkingInProgress] = useState(false); // Linking to Ayrshare Socials
  const [showSidebar, setShowSidebar] = useState(false);
  const [statusMessage, setStatusMessage] = useState(" ");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stripeStatus, setStripeStatus] = useState<string | undefined>(
    "unknown"
  );

  document.body.style.overflow = "auto";
  useEffect(() => {
    document.body.style.background = "#f6f6f6";
    if (Platform.OS === "web") {
      if (showSidebar) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    }
  }, [showSidebar]);

  const handleToggleSidebar = () => setShowSidebar((prevState) => !prevState);

  const handleGetUserData = async () => {
    //
    const mondayItem = storage.getString("user.mondayItem");
    const data = { uid: user?.uid };
    if (mondayItem === undefined) {
      if (!!user) {
        const result = await getUserData(data);
        const resp: any = result.data;
        console.log(resp);
        storage.set("user.mondayItem", resp.id);
        storage.set("user.name", resp.name);
        storage.set("user.signature", resp.signature);
        storage.set("user.hashtags", resp.hashtags);
        storage.set("user.businessName", resp.text68);
        storage.set("user.phoneNumber", resp.phone_number1);
        storage.set("user.signUpDate", resp.sign_up_date);
      }
    }
  };
  handleGetUserData();

  // * get sharedlink from dropbox and redirect to dropbox
  const handleSharedLink = () => {
    setSharingInProgress(true); // linking
    const data = { id: user?.uid, email: user?.email };
    getDropBoxUrl(data)
      .then((result) => {
        const url = result.data;
        if (typeof url === "string") {
          if (Platform.OS === "web") {
            window.open(url, "_blank", "noreferrer");
          } else {
            Linking.openURL(url);
          }
        } else {
          console.log("the URL is not a string");
          // Handle the case where the URL is not a string
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setSharingInProgress(false); // Set linkingInProgress back to false after 2 seconds
        }, 2000);
      });
  };

  const handleStripePortal = () => {
    const email = user?.email ? user?.email : "";
    const date = storage.getString("user.signUpDate")!;
    const signUpDate = Date.parse(date);
    let url = "";
    const newStripeDate = Date.parse("July 26, 2023");
    if (newStripeDate > signUpDate) {
      url = `https://billing.stripe.com/p/login/14k4k1b3d9SY88M6oo?prefilled_email=${encodeURIComponent(
        email
      )}`;
    } else {
      url = `https://billing.stripe.com/p/login/00g03W8567Py0Le000?prefilled_email=${encodeURIComponent(
        email
      )}`;
    }
    if (Platform.OS === "web") {
      window.open(url, "_blank", "noreferrer");
    } else {
      Linking.openURL(url);
    }
  };

  // Link social media accounts from Ayrshare
  const handleSocialMediaLink = () => {
    setLinkingInProgress(true);
    const data = { id: user?.uid };
    createAccount(data)
      .then((result) => {
        const url: any = result.data;
        if (Platform.OS === "web") {
          window.open(url, "_blank", "noreferrer");
        } else {
          Linking.openURL(url);
        }
      })
      .catch((error) => {
        console.error("Error linking social media accounts:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setLinkingInProgress(false); // Set linkingInProgress back to false after 2 seconds
        }, 2000);
      });
  };

  // * Check users account status via monday stripe status
  const handleVerification = () => {
    setIsLoading(true); // Start loading
    const data = { email: user?.email, id: user?.uid };
    checkEmail(data).then((result) => {
      setIsEmailVerified(user?.emailVerified || false);
      // Set the email verification status in local storage
      storage.set("emailVerified", user?.emailVerified || false);
      if (result.data === "Valid account.") {
        storage.set("user.accountStatus", "active");
        setStripeStatus("active");
        setStatusMessage("");
      } else if (result.data === "Inactive account.") {
        setStripeStatus("inactive");
        storage.set("user.accountStatus", "inactive");
        setStatusMessage(
          "It looks like your subscription has expired. Would you like to update billing? Use the link below."
        );
      } else if (result.data === "Customer not found.") {
        setStripeStatus("cancelled");
        storage.set("user.accountStatus", "cancelled");
        setStatusMessage(
          "It doesn't look like that email address is associated with an active Press Like account. Make sure you used the same email that you used at checkout!"
        );
      } else {
        storage.set("user.accountStatus", "unknown");
        setStripeStatus("unknown");
        setStatusMessage("Unknown account status. Please try again later.");
      }
      setTimeout(() => {
        setIsLoading(false); // End loading
      }, 3000);
    });
  };

  const handleResignUp = () => {
    Linking.openURL("https://www.amazingagent.com/join");
  };

  useEffect(() => {
    // Check the user's account status when the component mounts
    handleVerification();
  }, [user?.email, user?.emailVerified]);

  const renderConditionUi = () => {
    if (isLoading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color="rgb(0, 255, 51)" size="large" />
          <Text style={styles.loadingTitle}>Verifying Account Status...</Text>
        </View>
      );
    }
    if (stripeStatus === "active")
      return (
        <View style={styles.container}>
          <View style={styles.content}>
            <View style={styles.header}>
              <Text style={styles.header}>{`Welcome ${user?.email}! 😀`}</Text>
            </View>
            <Button
              onPress={handleSocialMediaLink}
              titleStyle={styles.buttonText}
              buttonStyle={styles.button}
              title="🔌 Link Social Accounts"
              disabled={linkingInProgress} // Disable the button when linking is in progress
            />
            {linkingInProgress && (
              <Text style={styles.loadingText}>Linking to Ayrshare...</Text>
            )}

            <Button
              title="📦 Dropbox (Manage Brand Assets)"
              titleStyle={styles.buttonText}
              buttonStyle={styles.button}
              onPress={handleSharedLink}
              disabled={sharingInprogress}
            ></Button>
            {/* Display a loading message when linking is in progress */}
            {sharingInprogress && (
              <Text style={styles.loadingText}>Generating Shared Link...</Text>
            )}

            <Button
              title="💳 Manage Billing Account"
              titleStyle={styles.buttonText}
              buttonStyle={styles.button}
              onPress={handleStripePortal}
            ></Button>
            <UpdateMonday uid={user?.uid} />
          </View>
        </View>
      );

    if (stripeStatus === "inactive") {
      return (
        <View style={styles.container}>
          <View style={styles.content}>
            <Text style={styles.header}>{`Welcome ${user?.email}! 😀`}</Text>
            <View style={styles.inputContainer}>
              <Text style={styles.description}>
                Please update your Billing Info
              </Text>
              <Text style={styles.label}>{statusMessage}</Text>
              <TouchableOpacity
                onPress={handleStripePortal}
                style={styles.buttonContainer}
              >
                <Text style={styles.buttonText}>Update Billing Info</Text>
                <Icon name="open-in-new" size={16} style={styles.icon} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    }
    return (
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.header}>{`Welcome ${user?.email}! 😀`}</Text>
          <View style={styles.inputContainer}>
            <Text style={styles.description}>
              Re-subscribe to use our services
            </Text>
            <Text style={styles.label}>{statusMessage}</Text>
            <TouchableOpacity
              onPress={() => {
                setInProgress(true);
                handleResignUp();
              }}
              style={styles.buttonContainer}
            >
              <Text style={styles.buttonText}>AmazingAgent.com</Text>
              <Icon name="open-in-new" size={16} style={styles.icon} />
            </TouchableOpacity>
            {inProgress && (
              <Text style={styles.loadingText}>
                Loading AmazingAgent.com...
              </Text>
            )}
          </View>
        </View>
      </View>
    );
  };
  return (
    <>
      {showSidebar && (
        <Sidebar
          navigation={navigation}
          toggleSidebar={handleToggleSidebar}
          sidebarVisible={showSidebar}
          isLoggedIn={isLoggedIn}
        />
      )}
      <Navigation
        navigation={navigation}
        toggleSidebar={handleToggleSidebar}
        sidebarVisible={showSidebar}
      />
      {renderConditionUi()}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1,
  },
  icon: {
    color: "#000",
  },
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  loadingTitle: {
    marginTop: 10,
    fontSize: 18,
    color: "#058DD8",
    fontFamily: "Montserrat-Regular",
  },
  header: {
    color: "black",
    borderRadius: 0,
    fontSize: 34,
    fontWeight: "500",
    padding: 10,
  },
  buttonText: {
    color: "white",
    fontWeight: "700",
    fontFamily: "Montserrat-Regular",
  },
  content: {
    marginTop: 100,
    maxHeight: "70%",
    height: 300,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
    fontFamily: "Montserrat-Regular",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#058DD8",
    borderRadius: 20,
    padding: 10,
  },
  button: {
    backgroundColor: "black",
    marginTop: 10,
    borderRadius: 20,
    fontFamily: "Montserrat-Regular",
  },
  statusButton: {},
  label: {
    fontSize: 16,
    fontWeight: "bold",
    margin: 10,
    fontFamily: "Montserrat-Regular",
  },
  email: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "grey",
    height: 40,
    paddingHorizontal: 5,
  },
  inputContainer: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderColor: "gray",
    paddingHorizontal: 10,
  },
  input: {
    flex: 1,
    height: 50,
    paddingVertical: 0,
    paddingHorizontal: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  description: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: "center",
    fontFamily: "Montserrat-Regular",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Montserrat-Regular",
    color: "white",
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    fontStyle: "italic",
    color: "gray",
  },
});

export default Account;
