import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import UserDashboard from "../screens/UserDashboard";
import ManageAccount from "../screens/ManageAccount";
import Splash from "../screens/Splash";
import { Platform } from "react-native";
import MyDesigns from "../screens/AllDesigns";


const Stack = createStackNavigator();

export default function UserStack() {
  const title = (text: string) =>
    Platform.select({ web: `Power House - ${text}`, default: text });
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false, // if false; removes default header when using Stack.Navigator
          cardStyle: {
            backgroundColor: "#F6F6F6",
          },
        }}
      >
        <Stack.Screen
          name="UserDashboard"
          component={UserDashboard}
          options={{ title: title("Dashboard") }}
        />
        <Stack.Screen
          name="Splash"
          component={Splash}
          options={{ title: title("Stand Out on Social Media") }}
        />
        <Stack.Screen
          name="All Designs"
          component={MyDesigns}
          options={{ title: title("All Designs") }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
