import React, { useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import Navigation from "../components/Navigation";
import Sidebar from "../components/Sidebar";
import { useAuthentication } from "../utils/hooks/useAuthentication";
import { FooterGrid } from "../components/Footer";
import { ScrollView } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/MaterialIcons"; 

const faqData = [
  {
    question:
      "Will I have to upload the graphic designs you create to my social media pages on my own?",
    answer: "No. We will create and post the content for you.",
  },
  {
    question: "How often will you post on my pages?",
    answer: "We will publish a minimum of 5 posts per week.",
  },
  {
    question: "Do I have to pay extra for sponsored ads?",
    answer: "No. We pay for the sponsored ads that we run on your behalf.",
  },
  {
    question: "Do I have to install any software?",
    answer:
      "No. However, you will be required to create a Social Pilot Account which we will guide you through.",
  },
  {
    question:
      "Can you handle the workload with all the agents you offer your services to?",
    answer:
      "Absolutely! Our team is equipped with adequate staff and the competency required to effectively manage our workload, giving each client individualized attention.",
  },
  {
    question: "Do I have to give you my passwords?",
    answer:
      "No, you will connect your social media accounts through a third party software. We will never ask for personal identifiable information. You will have full ownership of your social media accounts.",
  },
  {
    question: "How do I inform you of upcoming events and listings?",
    answer:
      "Simply Email, hello@presslike.com, or submit your request through the Member Dashboard. No need to call or talk to anyone! ",
  },
  {
    question: "How do I submit my requests?",
    answer:
      "Simply Email, hello@presslike.com, or submit your request through the Member Dashboard. No need to call or talk to anyone!",
  },
  // Add more FAQ items here
];

const FAQItem = ({ question, answer }:any) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <View style={styles.faqItem}>
      <TouchableOpacity onPress={() => setExpanded(!expanded)}>
        <View style={styles.faqItemHeader}>
          <Text style={styles.question}>{question}</Text>
          <Icon
            name={expanded ? "remove" : "add"} // Use "remove" icon when expanded, "add" icon when collapsed
            size={24}
            color="#333"
            style={styles.icon}
          />
        </View>
      </TouchableOpacity>
      {expanded && <Text style={styles.answer}>{answer}</Text>}
    </View>
  );
};

const FAQPage = ({navigation}:any) => {
  const { user } = useAuthentication();
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

   const toggleSidebar = () => {
     setIsSidebarOpen(!isSidebarOpen);
   };
  
  return (
    <View style={styles.container}>
      <Navigation toggleSidebar={toggleSidebar} navigation={navigation} />
      <Sidebar
        sidebarVisible={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        navigation={navigation}
        isLoggedIn={user}
      />
      <View style={styles.contentContainer}>
        <Text style={styles.title}>Frequently Asked Questions</Text>
        <ScrollView style={styles.content}>
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </ScrollView>
      </View>
      <FooterGrid />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 80,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 80,
    // minWidth:"40%",
  },
  content: {
    flex: 1,
    padding: 20,
    width: "50%",
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
    fontFamily: "Montserrat-Bold",
  },
  faqItem: {
    marginBottom: 20,
  },
  faqItemHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  question: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    fontFamily: "Montserrat-Regular",
  },
  answer: {
    marginTop: 5,
    color: "#666",
    fontSize: 16,
    fontFamily: "Montserrat-Regular",
  },
  icon: {
    marginLeft: 10,
  },
});

export default FAQPage;
