// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFunctions, httpsCallable } from "firebase/functions";
import 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDqoGtZPJJ5Rypu7aBwhZdrFUoF4_y0w8Y",
  authDomain: "pelagic-rig-386919.firebaseapp.com",
  projectId: "pelagic-rig-386919",
  storageBucket: "pelagic-rig-386919.appspot.com",
  messagingSenderId: "479742353896",
  appId: "1:479742353896:web:155f459159cf6d01f1045d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(); // Add this line to initialize the auth instance

export default app;
export { auth };

