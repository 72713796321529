import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import { Button } from "@rneui/themed";
import { storage } from "../utils/storage";

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const getUserData = httpsCallable(functions, "getMondayUserData");
const width = Dimensions.get("window").width;
// * defines onUpdate Prop & returns nothing =>  e.g, void
interface UpdateMondayProps {
  uid: string | undefined;
}

const UpdateMonday: React.FC<UpdateMondayProps> = ({ uid }) => {
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [signature, setSignature] = useState<string | undefined>();
  const [hashtags, setHashtag] = useState<string | undefined>();
  const [businessName, setBusinessName] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [mondayId, setMondayId] = useState<string | undefined>();
  const [submissionMessage, setSubmissionMessage] = useState("");

  // * calls the onUpdate callback with form values
  const handleSubmit = async (): Promise<void> => {
    // * handlesubmit tries to send the data back to the backend but trying to retrvive data and update monday.
    setIsSubmitted(() => true); // * if true the submit button will be hidden
    try {
      const response = await fetch(
        `https://us-central1-pelagic-rig-386919.cloudfunctions.net/updateMondayUserData`, // * updateMondayUserData GCF
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: mondayId,
            signature,
            hashtags,
            text68: businessName,
            phone_number1: phoneNumber,
          }),
        }
      );
      if (response.ok) {
        setSubmissionMessage("Form submitted successfully");
        setShowForm(false);
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      setSubmissionMessage("Failed to submit form");
    }
    setIsSubmitted(() => false);
    setTimeout(() => {
      setSubmissionMessage("");
    }, 5000); // Clear submission message after 5 seconds
    setShowForm(false);
  };

  const hideForm = () => {
    setShowForm(false);
  };

  const handleToggle = () => {
    const handleGetUserData = async () => {
      const data = { uid: uid };
      const result = await getUserData(data);
      const resp: any = result.data;
      console.log(resp)
      storage.set("user.mondayItem", resp.id);
      storage.set("user.signature", resp.signature);
      storage.set("user.hashtags", resp.hashtags);
      storage.set("user.businessName", resp.text68);
      storage.set("user.phoneNumber", resp.phone_number1);
      storage.set("user.signUpDate", resp.sign_up_date)
      storage.set("user.name", resp.name)
      
    };
    handleGetUserData();
    const sig = storage.getString("user.signature");
    const hash = storage.getString("user.hashtags");
    const text68 = storage.getString("user.businessName");
    const phone_number1 = storage.getString("user.phoneNumber");
    const id = storage.getString("user.mondayItem");
    setSignature(sig);
    setHashtag(hash);
    setBusinessName(text68);
    setPhoneNumber(phone_number1);
    setMondayId(id);
    setShowForm(true);
  };

  //* hides the button from the user to prevent multiple submissions
  // * when user submits(true) & button will show up if(false) it will show hide to prevent more submissions.
  //  * gets GCF url endpoint and retrieves userData from monday *(hash,businessName,sig,phone)*
  // * whenever the user gets into the first time the useEffect hook will run and call the getMondayUserData() function & replaces the user text input values.

  if (!showForm) {
    return (
      <View style={styles.container}>
        <Button
          titleStyle={styles.buttonText}
          buttonStyle={styles.button}
          title="📄 Update Account Details"
          onPress={handleToggle}
        />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        Update your post Signature, Hashtags, Business Name, and Contact Number
      </Text>
      <label style={styles.label}>Business Name</label>
      <TextInput
        style={styles.input}
        placeholder="Enter your Business Name"
        value={businessName}
        onChangeText={(text) => setBusinessName(text)}
      />
      <label style={styles.label}>Phone Number</label>
      <TextInput
        style={styles.input}
        placeholder="Enter your Phone Number"
        value={phoneNumber}
        onChangeText={(text) => setPhoneNumber(text)}
      />

      <label style={styles.label}>
        Hashtags separated by a comma e.g. (love, art, fashion)
      </label>
      <TextInput
        style={styles.hashtags}
        placeholder="Enter your Hashtags separated by a comma e.g. (love, art, fashion)"
        value={hashtags}
        onChangeText={(text) => setHashtag(text)}
      />
      <label style={styles.label}>Post Signature</label>
      <TextInput
        style={styles.sig}
        placeholder="Enter your Signature"
        value={signature}
        multiline={true}
        onChangeText={(text) => setSignature(text)}
      />

      {isSubmitted && <Text style={styles.message}>Submitting form...</Text>}
      {submissionMessage !== "" && (
        <Text style={styles.message}>{submissionMessage}</Text>
      )}
      <View style={styles.buttons}>
        <View style={{ padding: 10 }}>
          <Button
            titleStyle={styles.buttonText}
            buttonStyle={styles.button}
            title="Cancel"
            onPress={hideForm}
          />
        </View>
        <View style={{ padding: 10 }}>
          <Button
            titleStyle={styles.buttonText}
            buttonStyle={styles.button}
            title="Submit"
            onPress={handleSubmit}
            disabled={isSubmitted}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
  buttonText: {
    color: "white",
    fontWeight: "700",
    fontFamily: "Montserrat-Regular",
  },
  button: {
    backgroundColor: "black",
    borderRadius: 20
  },
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 16,
    margin: 10,
  },
  label: {
    fontFamily: "Montserrat-Regular",
    marginBottom: 3,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    margin: 16,
    textAlign: "center",
    fontFamily: "Montserrat-Regular",
  },
  sig: {
    flex: 1,
    width: Platform.OS === "web" ? "100%" : width * 0.8,
    marginBottom: 10,
    paddingHorizontal: 12,
    borderColor: "#aaa",
    borderWidth: 1,
    borderRadius: 30,
    fontFamily: "Montserrat-Regular",
    minHeight: 80,
    backgroundColor: "white",
    padding: 6,
  },

  hashtags: {
    flex: 1,
    width: Platform.OS === "web" ? "70%" : width * 0.8,
    marginBottom: 10,
    paddingHorizontal: 12,
    borderColor: "#aaa",
    borderWidth: 1,
    minHeight: 40,
    borderRadius: 30,
    fontFamily: "Montserrat-Regular",
    backgroundColor: "white",
  },

  input: {
    flex: 1,
    width: Platform.OS === "web" ? 300 : width * 0.8,
    minHeight: 40,
    marginBottom: 10,
    paddingHorizontal: 12,
    borderColor: "#aaa",
    borderWidth: 1,
    borderRadius: 30,
    fontFamily: "Montserrat-Regular",
    backgroundColor: "white",
  },
  message: {
    fontSize: 14,
    color: "green",
    marginBottom: 10,
  },
});

export default UpdateMonday;
