import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  Image,
  Pressable,
  Platform,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Svg, Path } from "react-native-svg";
import React, { useEffect, useState } from "react";

const windowWidth = Dimensions.get("window").width;

const TopNavBar = ({ toggleSidebar, navigation }: any) => {
  const [hideCustomerCareBtn, setHideCustomerCareBtn] = useState(false);

  const handleToggleSidebar = () => {
    toggleSidebar();
  };

  const handleWindowResize = () => {
    const { width } = Dimensions.get("window");
    setHideCustomerCareBtn(width < 680);
  };

  useEffect(() => {
    handleWindowResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <View style={[topNavBarStyle.container, topNavBarStyle.shadowProp]}>
      <View style={topNavBarStyle.leftSide}>
        <Pressable onPress={handleToggleSidebar}>
          <NavBarIcon />
        </Pressable>
        <TouchableOpacity onPress={() => navigation.navigate("Splash")}>
          <Image
            style={{ height: 50, width: 50, marginLeft: 10 }}
            source={require("../assets/ph_logo.png")}
          />
        </TouchableOpacity>
        <View style={topNavBarStyle.headerContainer}>
          <Text style={topNavBarStyle.textLogoBold}>Power</Text>
          <Text style={topNavBarStyle.textLogoTiny}>House</Text>
        </View>
      </View>
      <View>
        <Pressable
          onPress={() => {
            Linking.openURL("tel:503-374-1908");
          }}
        >
          <View style={topNavBarStyle.rightSideButton}>
            <PhoneIcon />
            {!hideCustomerCareBtn && (
              <Text style={topNavBarStyle.rightSideButtonText}>
                Customer Care
              </Text>
            )}
          </View>
        </Pressable>
      </View>
    </View>
  );
};

const NavBarIcon = () => (
  <Svg data-name="Layer 1" viewBox="0 0 64 64" width="24" height="24">
    <Path
      fill="none"
      stroke="#010101"
      strokeMiterlimit={10}
      strokeWidth={4}
      d="M0 15.95h64M0 32.15h64M0 48.05h64"
    />
  </Svg>
);

const PhoneIcon = () => (
  <Svg viewBox="0 0 29 29" width="16">
    <Path
      fill="#fff"
      d="m20.914 17.743-2.091 1.178a1.319 1.319 0 0 1-1.58-.217l-6.979-6.979a1.32 1.32 0 0 1-.217-1.58l1.178-2.091a1.978 1.978 0 0 0-.325-2.37L7.766 2.55a1.978 1.978 0 0 0-2.798 0L3.545 3.972a5.276 5.276 0 0 0-.793 6.446l.714 1.19a41.36 41.36 0 0 0 14.946 14.631l.141.081c2.102 1.201 4.699.851 6.382-.831l1.486-1.486a1.978 1.978 0 0 0 0-2.798l-3.136-3.136a1.978 1.978 0 0 0-2.371-.326z"
    />
  </Svg>
);

const topNavBarStyle = StyleSheet.create({
  container: {
    width: "80%",
    marginHorizontal: "auto",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical:16,
    backgroundColor: "#fff",
    minHeight: 80,
    margin: "auto",
    top: 0,
    left: "50%",
    transform: Platform.OS === "web" ? "translateX(-50%)" : "100%",
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    position: Platform.OS === "web" ? "fixed" : "absolute",
    zIndex: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
  },
  leftSide: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
  },
  headerContainer: {
    gap: 12,
  },
  textLogoTiny: {
    fontWeight: "500",
    lineHeight: 17,
    fontStyle: "normal",
    fontSize: windowWidth > 600 ? 33 : 18,
    letterSpacing: 10,
    fontFamily: "Montserrat-Alternates-Medium",
  },
  textLogoBold: {
    fontWeight: "600",
    fontSize: windowWidth > 600 ? 36 : 24,
    fontStyle: "normal",
    lineHeight: 22,
    fontFamily: "Montserrat-Alternates-Medium",
  },
  rightSideButton: {
    backgroundColor: "#121212",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    paddingHorizontal: 15,
    paddingVertical: 12,
    borderRadius: 22,
  },
  rightSideButtonText: {
    color: "#fff",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 14,
    fontFamily: "Montserrat-Bold",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as desired
    zIndex: 1,
  },
  sidebar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: Platform.OS === "web" ? "30%" : "50%", // Adjust the width as per your requirements
    height: "100%",
    backgroundColor: "#000",
    padding: 16,
    zIndex: 1,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: 8,
    zIndex: 3,
  },
  closeButtonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  sidebarButton: {
    padding: 20,
    fontSize: 16,
    fontWeight: "bold",
    color: "#fff",
  },
  sidebarXButton: {
    padding: 8,
    fontSize: 16,
    fontWeight: "bold",
    color: "#fff",
  },
});

export default TopNavBar;
