import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";
const windowWidth = Dimensions.get("window").width;
const containerWidth = windowWidth * 0.7;
import { useAuthentication } from "../utils/hooks/useAuthentication";

const PROMO_CARDS = [
  {
    title: "Social Media Banner Packs",
    description:
      "Distinguish yourself with a captivating and refined online presence Attract fresh clients with a stunning cover photo and banner, expertly crafted to elevate your brand. Let us handle it all for you!",
    image: require("../assets/promo_img/social_banner_2.png"),
  },
  {
    title: "Property Notices",
    description:
      "Stay ahead of the game and accelerate sales by delivering timely and engaging property announcements that keep your clients informed. Experience the power of effective communication with our expert services.",
    image: require("../assets/promo_img/property_an.png"),
  },
  {
    title: "Share Your Successes",
    description:
      "Highlight your achievements with confidence, proudly displaying client testimonials, prestigious awards, and noteworthy accolades. Let your success speak for itself and capture the attention of potential clients.",
    image: require("../assets/promo_img/share_success.png"),
  },
  {
    title: "Open House Visitor Kit",
    description:
      "Elevate your open house with a remarkable and unparalleled experience, setting yourself apart from the competition. Utilize these exceptional tools to exhibit your expertise and create a lasting impact on prospective buyers.",
    image: require("../assets/promo_img/open_house.png"),
  },
  {
    title: "Buyer/Seller Success Stories",
    description:
      "Show the behind-the-scenes steps from “Just Listed” to “Just Sold”! With a reel or a carousel-style post, you can give your audience a peek behind the curtains of your home prepping process, listing strategy and how you helped your clients reach their goals.",
    image: require("../assets/promo_img/buyer_seller.png"),
  },
  {
    title: "Market Updates",
    description:
      "Establish your expertise by providing valuable insights on local market statistics, including new listings, average time on the market, and median home sale prices. Showcase your industry knowledge and empower your audience with the information they need to make informed decisions.",
    image: require("../assets/promo_img/market_up.png"),
  },
];

const renderPromoCards = () =>
  PROMO_CARDS.map((card, index) => (
    <View
      style={[
        styles.container,
        index % 2 === 0 ? null : styles.reverseContainer,
      ]}
      key={card.title}
    >
      <View
        style={[
          styles.cardContent,
          index % 2 === 0 ? styles.leftPadding : styles.rightPadding,
        ]}
      >
        <Text style={styles.promoTitle}>{card.title}</Text>
        <Text style={styles.cardDescription}>{card.description}</Text>
      </View>
      <View
        style={[
          styles.cardImage,
          index % 2 === 0 ? styles.imageStart : styles.imageEnd,
        ]}
      >
        <Image style={styles.imageStyle} source={card.image} />
      </View>
    </View>
  ));

function PromoCard({ navigation }: any) {
  const { user } = useAuthentication();

  const handleMyAccountButton = () => {
    if (user) {
      navigation.navigate("UserDashboard");
    } else {
      navigation.navigate("Sign In");
    }
  };
  return (
    <View style={styles.promoCardContainer}>
      <Text>{renderPromoCards()}</Text>
      <View>
        <TouchableOpacity
          style={styles.myAccountContainer}
          onPress={handleMyAccountButton}
        >
          <Text style={styles.myAccountText}>My Account</Text>
          <Text style={styles.myAccountText}>{" > "}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.blurbContainer}>
        <Text style={styles.blurbTextTitle}>
          Update Your Profile with Headshots, Logos, and Captions.
        </Text>
        <Text style={styles.blurbText}>
          Ensure your profile remains up-to-date by sharing your latest
          headshots, logos, and contact details. Take a moment to submit new
          images and information for us to showcase on your account. Custom
          graphics created through the Design Studio will include your provided
          headshots and logos. Additionally, every caption featuring a
          call-to-action will display your contact information.
        </Text>
      </View>
    </View>
  );
}

export default PromoCard;

const styles = StyleSheet.create({
  promoCardContainer: {
    backgroundColor: "#fff",
    padding: Platform.OS === "web" ? (windowWidth > 600 ? 20 : "20px 0 20px") : 5,
    marginTop: windowWidth > 600 ? 0 : 20,
    //
  },
  blurbContainer: {
    padding: 15,
    backgroundColor: "#f4f3f4",
    borderRadius: 8,
    marginTop: 10,
  },
  blurbTextTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 10,
    fontFamily: "Montserrat-Regular",
  },
  blurbText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "Montserrat-Regular",
  },
  myAccountContainer: {
    marginTop: 5,
    marginLeft: "auto",
    margin: 5,
    borderRadius: 30,
    borderColor: "#000",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 10,
    width: "100%",
    flexDirection: "row", // Add flexDirection to align items horizontally
    alignItems: "center", // Center the items vertically
    justifyContent: "space-between",
    backgroundColor: "rgba( 0, 0, 0, 1)",
  },
  myAccountText: {
    fontSize: 16,
    lineHeight: 30,
    color: "#fff",
    fontWeight: "bold",
    paddingHorizontal: 10,
    fontFamily: "Montserrat-Bold",
  },
  container: {
    display: "flex",
    flexDirection: windowWidth > 600 ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "auto",
    marginBottom: windowWidth > 600 ? 10 : 30,
  },
  reverseContainer: {
    flexDirection: windowWidth > 600 ? "row-reverse": "column",
  },
  leftPadding: {
    paddingLeft: 20,
    paddingRight: windowWidth > 990 ? 80 : windowWidth > 600 ? 20 : 0,
  },
  rightPadding: {
    paddingLeft: windowWidth > 990 ? 80 : windowWidth > 600 ? 20 : 0,
    paddingRight: 20,
  },
  cardContent: {
    width: windowWidth > 600 ? containerWidth : '100%',
    flex: 1,
    alignItems: "center",
    maxWidth: Platform.OS === "web" ? windowWidth > 600 ? "50%" : "95%" : containerWidth * 0.35,
    flexWrap: "wrap",
  },
  promoTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: windowWidth > 1220 ? 48 : windowWidth > 670 || windowWidth > 440 && windowWidth < 600 ? 36 : 24,
    fontWeight: "700",
    lineHeight: windowWidth > 1220 ? 48 : windowWidth > 670 || windowWidth < 600 ? 36 : 24,
    marginBottom: 10,
    alignItems: "flex-start",
    flexWrap: "wrap", // Added flexWrap property
  },
  cardDescription: {
    alignItems: "flex-start",
    fontFamily: "Montserrat-Regular",
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 24,
    flexWrap: "wrap",

    marginBottom: 10,
  },
  cardImage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  imageStart: {
    alignItems: "flex-start",
  },
  imageEnd: {
    alignItems: "flex-end",
  },
  imageStyle: {
    resizeMode: "contain",
    width: "100%",
    height: windowWidth > 600 ? undefined : "250px",
    aspectRatio: 1,
  },
});
