import React, { useEffect } from 'react'; 
import Iframe from 'react-iframe'; 
import { storage } from '../utils/storage'; 
interface FormProps { 
    url: string; 
  } 
 const Form = ({ url }: FormProps) => { 
  useEffect(() => { 
    const iframe = document.getElementById('myIframe'); 
     
    if (iframe && iframe instanceof HTMLIFrameElement && iframe.contentWindow) { 
     
    const userName = storage.getString('user.name') 
    const sig = storage.getString("user.signature"); 
    const hashtags = storage.getString("user.hashtags"); 
    const businessName= storage.getString("user.businessName"); 
    const phoneNumber = storage.getString("user.phoneNumber"); 
    const id = storage.getString("user.mondayItem"); 
    iframe.addEventListener('load', () => { 
        const contentWindow = iframe.contentWindow as Window; 
        const injectedCode = ` 
            const iframe = document.getElementById('myIframe').contentWindow;
            console.log(iframe)
            iframe.document.querySelectorAll('[autocomplete="name"]').value = "${userName}";
        `; 
        const executeCode = new Function('userName', injectedCode); 
        executeCode.call(contentWindow, userName); 
    }) 
    } 
  }, []); 
   return ( 
    <Iframe 
      url={url} 
      width="100%" 
      height="100%" 
      frameBorder={0} 
      id='myIframe' 
    /> 
  ); 
}; 
 export default Form; 
 