import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet, Dimensions, Platform, Animated, Linking } from "react-native";
import { storage } from "../utils/storage";
import Form from "./Form";
import { useAuthentication } from "../utils/hooks/useAuthentication";


const windowWidth = Dimensions.get("window").width;
const containerWidth = windowWidth * 0.7;


interface DesignCardProps {
  card: any;
  index: number;
  isResponsive: boolean;
  expandedCardIndex: number;
  setExpandedCardIndex: React.Dispatch<React.SetStateAction<number>>;
}

const DesignCard: React.FC<DesignCardProps> = ({
  card,
  index,
  isResponsive,
  expandedCardIndex,
  setExpandedCardIndex
}) => {
  let { user } = useAuthentication();
  
  const isExpanded = index === expandedCardIndex;
  const [animation] = useState(new Animated.Value(isExpanded ? 1 : 0));

  const handleStripePortal = () => {
    const email = user?.email ? user?.email : "";
    const date = storage.getString("user.signUpDate")!;
    const signUpDate = Date.parse(date);
    let url = "";
    const newStripeDate = Date.parse("July 26, 2023");
    if (newStripeDate > signUpDate) {
      url = `https://billing.stripe.com/p/login/14k4k1b3d9SY88M6oo?prefilled_email=${encodeURIComponent(
        email
      )}`;
    } else {
      url = `https://billing.stripe.com/p/login/00g03W8567Py0Le000?prefilled_email=${encodeURIComponent(
        email
      )}`;
    }
    if (Platform.OS === "web") {
      window.open(url, "_blank", "noreferrer");
    } else {
      Linking.openURL(url);
    }
  };

  useEffect(() => {
    Animated.timing(animation, {
      toValue: isExpanded ? 1 : 0,
      duration: 300,
      useNativeDriver: false, // Set this to 'false' for Android support
    }).start();
  }, [isExpanded]);

  const handleCardExpand = () => {
    // if user has active account : 
    const stripeStatus = storage.getString('user.accountStatus')
    if(stripeStatus === "active") {
      setExpandedCardIndex(index === expandedCardIndex ? -1 : index);
    } else if(stripeStatus === "inactive"){
      handleStripePortal();
  } else{
    const url = "https://www.amazingagent.com/standout"
    if(Platform.OS === "web") {
      window.open(url, "_blank", "noreferrer");
    } else {
      Linking.openURL(url);
    }
  }
}
const buttonText = () => {
  const stripeStatus = storage.getString('user.accountStatus')
  if(stripeStatus === "active") {
    return "Request Design"
  } else if(stripeStatus === "inactive"){
    return "Update Billing to Request"
} else{
    return "Subscribe to Request"
}
}
  const containerStyle = {
    ...styles.container,
    flex: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, isResponsive ? 1.2 : 1.5],
    }),
  };
const url = (card.form_link || "")
  return (
<Animated.View style={[
        containerStyle,
        isResponsive ? { flexDirection: "column" } : { flexDirection: "row" },
        index % 2 === 0 || isResponsive ? null : styles.reverseContainer,
      ]}>
      {expandedCardIndex === index ? (
        <Form url={url}/>
      // <Iframe
      //   url={card.form_link || ""}
      //   width="100%"
      //   height="100%"
      //   frameBorder={0}
      //   onLoad={handleIframeLoad}
      // />
      ) : (
        <>
          <View
            style={[
              styles.cardContent,
              !isResponsive &&
                (index % 2 === 0 ? styles.leftPadding : styles.rightPadding),
            ]}
          >
            <Text style={styles.designTitle}>{card.title}</Text>
            <Text style={styles.cardDescription}>{card.description}</Text>
            <TouchableOpacity style={styles.button} onPress={handleCardExpand}>
              <Text style={styles.buttonText}>{buttonText()}</Text>
            </TouchableOpacity>
          </View>
          
          <View
            style={[
              styles.cardImage,
              index % 2 === 0 ? styles.imageStart : styles.imageEnd,
            ]}
          >
            <Image
              style={[styles.imageStyle, isResponsive && { minHeight: 300 }]}
              source={card.image}
            />
          </View>
        </>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  promoCardContainer: {
    backgroundColor: "#fff",
    padding: Platform.OS === "web" ? 20 : 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "auto",
    flexWrap: "wrap",
    minWidth: "100%",
    paddingTop: Platform.OS === "web" ? 120 : 100,
  },
  blurbContainer: {
    padding: 15,
    borderRadius: 8,
    marginTop: 10,
    marginHorizontal: "auto",
    maxWidth: "80%",
  },
  blurbTextTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 10,
    fontFamily: "Montserrat-Regular",
  },
  blurbText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "Montserrat-Regular",
  },
  myAccountContainer: {
    marginTop: 5,
    borderRadius: 30,
    borderColor: "#000",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 10,
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  myAccountTextContainer: {
    justifyContent: "center",
  },
  myAccountTextLeft: {
    justifyContent: "flex-start",
  },
  myAccountTextRight: {
    justifyContent: "flex-end",
  },
  myAccountText: {
    fontSize: 16,
    lineHeight: 30,
    color: "#fff",
    fontWeight: "bold",
    paddingHorizontal: 10,
    fontFamily: "Montserrat-Bold",
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 35,
    borderRadius: 30,
    width: "100%",
    paddingHorizontal: 5,
    paddingVertical: 20,
    backgroundColor: "#f2f2f2",
    maxWidth: "80%",
  },
  reverseContainer: {
    flexDirection: "row-reverse",
  },
  leftPadding: {
    paddingLeft: 20,
    paddingRight: 80,
  },
  rightPadding: {
    paddingLeft: 80,
    paddingRight: 20,
  },
  cardContent: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: Platform.OS === "web" ? "80%" : containerWidth * 0.35,
    flexWrap: "wrap",
    minHeight:'fit-content',
  },
  designTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: Platform.OS === "web"? 48 : 24,
    fontWeight: "700",
    lineHeight: 48,
    textAlign: "center",
    marginBottom: 10,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  cardDescription: {
    alignItems: "flex-start",
    fontFamily: "Montserrat-Regular",
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 24,
    flexWrap: "wrap",
    marginBottom: 10,
  },
  cardImage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: 1,
  },
  imageStyle: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
    maxWidth: "auto",
    minHeight:300,
    aspectRatio: 1,
  },
  imageStart: {
    alignItems: "center",
  },
  imageEnd: {
    alignItems: "center",
  },
  button: {
    backgroundColor: "#007BFF",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default DesignCard;
