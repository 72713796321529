import { View } from "react-native";
import Join from "./Join";
import Services from "./Services";
import React from "react";

function Marketing() {
  return (
    <>
      <View>
        <Join />
        <Services />
      </View>
    </>
  );
}

export default Marketing;
