import React from "react";
import { Dimensions, View, Image, StyleSheet } from "react-native";

const windowWidth = Dimensions.get('window').width

function QR() {
  
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          source={require("../assets/qr_image.png")}
        />
      </View>
    </View>
  );
}

export default QR;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fae9fa",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    width: "100%",
    height: windowWidth > 700 ? undefined : 250,
    aspectRatio: 3 / 1,
  },
  image: {
    margin: "auto",
    resizeMode: "contain",
    flex: 1,
    width: windowWidth > 700 ? 700 : '100%',
    height: 950,
  },
});
