import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import SignIn from "../screens/SignIn";
import SignUp from "../screens/SignUp";
import Splash from "../screens/Splash";
import { Platform } from "react-native";
import MyDesigns from "../screens/AllDesigns";
import FAQPage from "../screens/FAQPage";

const Stack = createStackNavigator();

export default function AuthStack() {
  // changing page tile on web tab
  const title = (text: string) =>
    Platform.select({ web: `Power House - ${text}`, default: text });
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false, // if false; removes default header when using Stack.Navigator
          cardStyle: {
            backgroundColor: "#F6F6F6",
          },
        }}
      >
        <Stack.Screen
          name="Splash"
          component={Splash}
          options={{ title: title("Stand Out on Social Media") }}
        />
        <Stack.Screen
          name="All Designs"
          component={MyDesigns}
          options={{ title: title("All Designs") }}
        />
        <Stack.Screen
          name="Sign Up"
          component={SignUp}
          options={{ title: title("Sign Up") }}
        />
        <Stack.Screen
          name="Sign In"
          component={SignIn}
          options={{ title: title("Sign In") }}
        />
        <Stack.Screen
          name="FAQ Page"
          component={FAQPage}
          options={{ title: title("FAQ") }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
