import React from 'react'
import { Dimensions, View, Text, StyleSheet, Linking, TouchableOpacity } from "react-native"
import QR from './QR';
import Support from './Support';
import { useAuthentication } from "../utils/hooks/useAuthentication";

const windowWidth = Dimensions.get("window").width;

export const FooterGrid = ({ navigation }: any) => {
  
  const columnHeaders = ["contact", "hours", "account", "careers"];
  const { user } = useAuthentication();

  const gridText = [
    {
      text: "+1(503)-374-1908",
      onPress: () => {
        Linking.openURL("tel:+15033741908");
      },
    },
    {
      text: "PO Box 159 Prospect, OR 97536",
    },
    {
      text: "Monday-Friday\n8:00 AM - 5:00 PM PST",
    },
    {
      text: "", 
      onPress: () => {}, 
    },
    {
      text: "Create Account",
      onPress: () => {
        if (!user) {
          navigation.navigate("Sign Up");
        }
      },
    },
    {
      text: "Update Payment Information",
      onPress: () => {
        if (user) {
          navigation.navigate("UserDashboard");
        } else {
          navigation.navigate("Sign In");
        }
      },
    },
    {
      text: "", 
      onPress: () => {},
    },
    {
      text: "View Careers",
      onPress: () => {
        Linking.openURL("https://www.100kmind.com/careers");
      },
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {columnHeaders.map((header, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <View style={styles.separator} />}
            <View style={styles.column}>
              <Text style={styles.header}>{header}</Text>
              {gridText.slice(index * 2, (index + 1) * 2).map((item, idx) => (
                <TouchableOpacity
                  key={idx}
                  onPress={item.onPress}
                  disabled={!item.onPress}
                >
                  <Text
                    style={[
                      styles.text,
                      index === 1 && styles.centerAlignedText,
                      !item.onPress && styles.nonClickableText,
                    ]}
                  >
                    {item.text}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </React.Fragment>
        ))}
      </View>
    </View>
  );
};

function Footer({navigation}: any) {
  return (
    <View>
      <QR/>
      <Support />
      <FooterGrid navigation={navigation} />
    </View>
  );
}

export default Footer

const styles = StyleSheet.create({
  container: {
    backgroundColor: "black",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingHorizontal: 30,
    paddingTop: 30,
    paddingBottom: windowWidth > 700 ? 100 : 0,
    gapHorizontal: 20,
  },
  nonClickableText: {
    color: 'gray',
  },
  centerAlignedText: {
    textAlign: "center",
  },
  row: {
    flexDirection: windowWidth > 700 ? "row" : "column",
    gap: 10,
    justifyContent: "space-evenly",
    marginBottom: 10,
    flexWrap: "wrap",
  },
  column: {
    flex: 1,
    alignItems: "center",
  },
  header: {
    color: "white",
    fontSize: 20,
    lineHeight: 38,
    fontFamily: "Montserrat-Bold",
    fontWeight: "600",
    textTransform: "uppercase",
  },
  text: {
    fontFamily: "Montserrat-Regular",
    color: "white",
    fontSize: 16,
    lineHeight: 38,
    fontWeight: "300",
    textAlign: "center",
  },
  separator: {
    width: 20, 
  },
});