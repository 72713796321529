import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";
import { useAuthentication } from "../utils/hooks/useAuthentication";
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import { FooterGrid } from "../components/Footer";
import DesignCard  from "../components/DesignCard"
const windowWidth = Dimensions.get("window").width;
const containerWidth = windowWidth * 0.7;

const MY_DESIGNS = [
  {
    title: "Local Events",
    description:
      "Celebrate and show some love to your most cherished local event! Whether it's a heartwarming holiday festivity or a meaningful food drive, let's illuminate the incredible array of things to experience and participate in within our community.",
    image: require("../assets/design_img/local_events.png"),
    form_link: 'https://forms.monday.com/forms/embed/f0a197e80aff52ec184bab012349351f?r=use1'
  
  },
  {
    title: "Local Spotlight",
    description:
      "Big shoutout to local entrepreneurs & businesses! 📣 Trusted lenders, baristas, and innovators, you rock! Thanks for flourishing our community! 👏👏👏 #SupportLocal #Entrepreneurship",
    image: require("../assets/design_img/local_spotlight.png"),
    form_link:'https://forms.monday.com/forms/embed/d35368242ed6f830ae0f0ac137c5821e?r=use1'
   
  },
  {
    title: "Open House Announcement",
    description:
      "Important Notice: Open House requests must be submitted by Friday at 11:00 AM PST for timely delivery. Thank you for your cooperation! 🏠🕚",
    image: require("../assets/design_img/open_house_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/fa365f3574c79ae11e5a8361fb26dbd8?r=use1'

  },
  {
    title: "Coming Soon Announcement",
    description: "",
    image: require("../assets/design_img/coming_soon_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/179bd45c648e0ea3990d808b63db1bb4?r=use1'
    
  },
  {
    title: "Just Listed Announcement",
    description: "",
    image: require("../assets/design_img/just_listed_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/9b88f611df8c1edffbed2f488b0db78b?r=use1'

  },
  {
    title: "For Sale Announcement",
    description: "",
    image: require("../assets/design_img/for_sale_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/f97f721f0d7867920e26083a757a7222?r=use1'
    
  },
  {
    title: "Price Improvement Announcement",
    description: "",
    image: require("../assets/design_img/price_improvement_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/4080278bfafda3f3f056c224d25cea7b?r=use1'
  
  },
  {
    title: "Under Contract Announcement",
    description: "",
    image: require("../assets/design_img/under_contract_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/c963008a4ac55e3ad6a5a367536a898a?r=use1'
    
  },
  {
    title: "Offer Accepted Announcement",
    description: "",
    image: require("../assets/design_img/offer_accepted_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/91659331145b623f63f7980758a5d021?r=use1'
  
  },
  {
    title: "Seller Success Story",
    description:
      "Unveil the journey from 'Just Listed' to 'Just Sold'! Witness captivating home prepping, strategic listing, and the success stories of our clients! 🏠🎉 #RealEstate #BehindTheSceney",
    image: require("../assets/design_img/seller_sucess_story.png"),
    form_link: 'https://forms.monday.com/forms/embed/0af8ca65f888e622a46b28a5ff72b94c?r=use1'
  
  },
  {
    title: "Home Buyer Success Story",
    description:
      "🏠 Peek behind the curtains! Unveil buying magic, expert negotiation, and clients' dream homes. 🏡✨ #RealEstate #BuyingProcess #DreamHome",
    image: require("../assets/design_img/home_buyer_sucess_story.png"),
    form_link:'https://forms.monday.com/forms/embed/e6dce74eac41cf19f4063ce5f0227988?r=use1'
  
  },
  {
    title: "Just Sold Announcement",
    description: "",
    image: require("../assets/design_img/just_sold_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/f00ac51b2329c1f89def8701d5422cfd?r=use1'
  
  },
  {
    title: "Just Closed Announcement",
    description: "",
    image: require("../assets/design_img/just_closed_announcement.png"),
    form_link:'https://forms.monday.com/forms/embed/6f860b3fbf3ff601cd3e179d8d078637?r=use1'
   
  },
  {
    title: "Client on Closing Day",
    description: "",
    image: require("../assets/design_img/client_on_closing_day.png"),
    form_link:'https://forms.monday.com/forms/embed/90a412dd779ac4268532fbea99854c07?r=use1'
  
  },
  {
    title: "Five Star Testimonial",
    description:
      "🌟 #FiveStarFriday Testimonials! 🏡 Outstanding service finding our dream home, stress-free negotiation, and a smooth first home purchase. Join us every Friday for more heartfelt reviews! 😊 #ClientLove",
    image: require("../assets/design_img/five_star_testimonial.png"),
    form_link:'https://forms.monday.com/forms/embed/1418a0ad8780f639a16391561a3c9aa0?r=use1'
    
  },
  {
    title: "Open House Visitor Kit",
    description:
      "Let our Design Team create a personalized Open House Visitor Kit with your logo, photo, and brand colors! Fill out the form now for your customized kit. 🎨📋 #OpenHouse #CustomDesign",
    image: require("../assets/design_img/open_house_visitor_kit.png"),
    form_link: 'https://forms.monday.com/forms/embed/adaf3e7f98e77c20ef481504abc335e3?r=use1'
    
 
  },
  {
    title: "Monthly Marketing Updates",
    description:
      "Fantastic! The local real estate market is booming. Prices soar, and demand stays robust. #RealEstate #MarketUpdate",
    image: require("../assets/design_img/monthly_market_updates.png"),
    form_link:'https://forms.monday.com/forms/embed/de133316aeae20fd4405b108dbcbe08e?r=use1'
   
  },
  {
    title: "Personal Logo Request",
    description:
      "Experience our stunning logo designs! Click the link below to explore the options. Once you've found your favorite, fill out the form to kickstart your journey! 🎨🖱️ #LogoDesign #CreativityUnleashed",
    image: require("../assets/design_img/logo_request.png"),
    form_link:'https://forms.monday.com/forms/embed/81e1734aa72542c6faa74f49dd57ed05?r=use1'

   
  },
  {
    title: "Social Media Banner Pack",
    description:
      "Elevate your online presence! Get a Realtor.com profile banner, Facebook cover, LinkedIn banner, and Pinterest account banner. Maximize your brand's impact today! 🌟🖼️ #OnlinePresence #BrandBoost",
    image: require("../assets/design_img/social_media_banner_pack.png"),
    form_link:'https://forms.monday.com/forms/embed/69b9333c8fc9acc06cb413eff14a5a65?r=use1'
  },
  {
    title: "Personal Email Signature",
    description: "",
    image: require("../assets/design_img/email_signature.png"),
    form_link:'https://forms.monday.com/forms/embed/17895932fab2e1f9549b308d72e66f2a?r=use1'
  },
];

const renderMyDesigns = (isResponsive: boolean) => {
  
  const [expandedCardIndex, setExpandedCardIndex] = useState<number>(-1);

  return MY_DESIGNS.map((card, index) => (
    
    <DesignCard
      key={card.title}
      card={card}
      index={index}
      isResponsive={isResponsive}
      expandedCardIndex={expandedCardIndex}
      setExpandedCardIndex={setExpandedCardIndex}
    />
  ));
};

const MyDesigns = ({ navigation}: any) => {
  const { user } = useAuthentication();
  const [showSidebar, setShowSidebar] = useState(false);
  
  useEffect(() => {
    if (Platform.OS === "web") {
      if (showSidebar) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    }
  }, [showSidebar]);
  const handleToggleSidebar = () => setShowSidebar((prevState) => !prevState);

  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const { width } = Dimensions.get("window");
    setIsResponsive(() => Platform.OS !== "web" || width <= 1000);
    const handleWindowResize = () => {
      const { width } = Dimensions.get("window");
      setIsResponsive(() => Platform.OS !== "web" || width <= 1000);
    };
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      {showSidebar && (
        <Sidebar
          navigation={navigation}
          toggleSidebar={handleToggleSidebar}
          sidebarVisible={showSidebar}
          isLoggedIn={user}
        />
      )}
      <Navigation
        navigation={navigation}
        toggleSidebar={handleToggleSidebar}
        sidebarVisible={showSidebar}
      />
      <View style={[styles.promoCardContainer
      ]}>
        {renderMyDesigns(isResponsive)}
      </View>
      <FooterGrid navigation={navigation} />
    </>
  );
};


const styles = StyleSheet.create({
  promoCardContainer: {
    backgroundColor: "#fff",
    padding: Platform.OS === "web" ? 20 : 5,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "auto",
    flexWrap: "wrap",
    paddingTop: Platform.OS === "web" ? 120 : 100,
    flex: 1, // Allow the container to grow and shrink
  },
  blurbContainer: {
    padding: 15,
    borderRadius: 8,
    marginTop: 10,
    marginHorizontal: "auto",
    maxWidth: "80%",
  },
  blurbTextTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 10,
    fontFamily: "Montserrat-Regular",
  },
  blurbText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "Montserrat-Regular",
  },
  myAccountContainer: {
    marginTop: 5,
    borderRadius: 30,
    borderColor: "#000",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 10,
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  },
  myAccountTextContainer: {
    justifyContent: "center",
  },
  myAccountTextLeft: {
    justifyContent: "flex-start",
  },
  myAccountTextRight: {
    justifyContent: "flex-end",
  },
  myAccountText: {
    fontSize: 16,
    lineHeight: 30,
    color: "#fff",
    fontWeight: "bold",
    paddingHorizontal: 10,
    fontFamily: "Montserrat-Bold",
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 35,
    borderRadius: 30,
    width: "100%",
    paddingHorizontal: 5,
    paddingVertical: 20,
    backgroundColor: "#f2f2f2",
    maxWidth: "80%",
  },
  reverseContainer: {
    flexDirection: "row-reverse",
  },
  leftPadding: {
    paddingLeft: 20,
    paddingRight: 80,
  },
  rightPadding: {
    paddingLeft: 80,
    paddingRight: 20,
  },
  cardContent: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: Platform.OS === "web" ? "80%" : containerWidth * 0.35,
    flexWrap: "wrap",
  },
  designTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: Platform.OS === "web" ? 48 : 24,
    fontWeight: "700",
    lineHeight: 48,
    textAlign: "center",
    marginBottom: 10,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  cardDescription: {
    alignItems: "flex-start",
    fontFamily: "Montserrat-Regular",
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 24,
    flexWrap: "wrap",
    marginBottom: 10,
  },
  cardImage: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: 1,
  },
  imageStyle: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
    maxWidth: "auto",
    aspectRatio: 1,
  },
  imageStart: {
    alignItems: "center",
  },
  imageEnd: {
    alignItems: "center",
  },
  button: {
    backgroundColor: "#007BFF",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
});


export default MyDesigns;