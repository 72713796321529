import React from "react";
import { View, Text, StyleSheet, Dimensions, Platform } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
const width = Dimensions.get("window").width;

const OFFERS_LIST = [
  {
    icon: {
      name: "users",
      size: Platform.OS === "web" ? 50 : 30,
    },
    title: "Social Media Banner Packs",
    subtitle: "",
  },
  {
    icon: {
      name: "home",
      size: Platform.OS === "web" ? 50 : 30,
    },
    title: "Property Notices",
    subtitle: "",
  },
  {
    icon: {
      name: "trophy",
      size: Platform.OS === "web" ? 50 : 30,
    },
    title: "Success Story",
    subtitle: "",
  },
  {
    icon: {
      name: "file",
      size: Platform.OS === "web" ? 50 : 30,
    },
    title: "Open House Visitor Kits",
    subtitle: "",
  },
  {
    icon: {
      name: "money",
      size: Platform.OS === "web" ? 50 : 30,
    },
    title: "Buy/Sell Success Stories",
    subtitle: "",
  },
  {
    icon: {
      name: "line-chart",
      size: Platform.OS === "web" ? 50 : 30,
    },
    title: "Market Updates",
    subtitle: "",
  },
];

function renderIcon(name: string, size: number) {
  return <Icon name={name} size={size} />;
}

function Offers() {
  const renderOffers = () =>
    OFFERS_LIST.map((offer) => (
      <View key={offer.title} style={styles.offerContainer}>
        <Text style={[styles.textColor, styles.icon]}>
          {renderIcon(offer.icon.name, offer.icon.size)}
        </Text>
        <View>
          <Text style={[styles.textColor, styles.title]}>{offer.title}</Text>
          <Text style={[styles.textColor, styles.subtitle]}>
            {offer.subtitle}
          </Text>
        </View>
      </View>
    ));

  return (
    <View style={styles.container}>
      <Text>{renderOffers()}</Text>
    </View>
  );
}

export default Offers;

const styles = StyleSheet.create({
  container: {
    flexDirection: Platform.OS === "web" ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    zIndex: -1,
  },
  offerContainer: {
    marginBottom: 12,
    padding: 20,
    zIndex: -1,
  },
  textColor: {
    color: "#fff",
  },
  icon: {
    paddingBottom: 5,
    alignItems: "center",
    lineHeight: 30,
  },
  title: {
    fontWeight: "700",
    fontSize: 24,
    lineHeight: 36,
    textAlign: "center",
    fontFamily: "Montserrat-Bold",
  },
  subtitle: {
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Montserrat-Regular",
  },
});
