import React, { useState, useEffect } from "react";
import {ThemeProvider} from '@rneui/themed'

import "./src/config/firebase";
import RootNavigation from "./src/navigation";
import Loading from "./src/components/Loading";


export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
      <ThemeProvider >
        <RootNavigation />
      </ThemeProvider>
  );
}
