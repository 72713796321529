import React, { useEffect } from "react";
import { View, Text, ActivityIndicator, StyleSheet } from "react-native";
import * as Font from "expo-font";

const Loading = ({
  size = 100,
  color = "#0000ff",
  message = "Please wait while the page loads...",
}) => {

  useEffect(() => {
    async function loadFont() {
      await Font.loadAsync({
        "Montserrat-Regular": {
          uri: "https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.1/demo/css/montserrat-regular.ttf",
        },
        // Add other variants if needed
        "Montserrat-Bold": {
          uri: "https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.1/demo/css/montserrat-bold.ttf",
        },
        "Montserrat-Alternates": {
          uri: "https://db.onlinewebfonts.com/t/73e177b8f64802141601085dd1c1fc66.ttf",
        },
        "Montserrat-Alternates-Medium": {
          uri: "https://db.onlinewebfonts.com/t/701a65adb3bd29c56e9443330538b467.ttf",
        },
      });
    }

    loadFont();
  }, []);

  return (
    <View style={styles.container}>
      <ActivityIndicator size={size} color={color} />
      <Text style={styles.message}>{message}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  message: {
    marginTop: 10,
    fontWeight: "700",
    fontSize: 20,
    fontFamily: "Montserrat-Regular",
    color: "#333",
  },
});

export default Loading;
