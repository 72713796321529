import React from "react";
import { TouchableOpacity } from "react-native";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Linking,
  Pressable,
} from "react-native";


function Join() {
  return (
    <View style={styles.container}>
      <View style={styles.leftSide}>
        <View style={styles.iconSide}>
          <Text style={styles.icon}>🖥</Text>
        </View>

        <View style={styles.buttonContainer}>
          <View style={styles.joinContainer}>
            <Text style={styles.joinTeamText}> JOIN THE TEAM</Text>
          </View>

          <Text style={styles.applyToPressLikeText}>
            {" "}
            Apply to Work at Power House
          </Text>
        </View>
      </View>

      <View style={styles.rightSide}>
        <Pressable
          onPress={() => {
            Linking.openURL("https://www.100kmind.com/careers");
          }}
        >
          <View style={styles.applyTodayButton}>
            <Text style={styles.applyTodayButtonText}>Apply Today {">"}</Text>
          </View>
        </Pressable>
      </View>
    </View>
  );
}

export default Join;

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-evenly",
    flexDirection: Platform.OS === "web" ? "row" : "column",
    alignItems: "center",
    textAlign: "center",
    flexShrink: 1,
    flexWrap: "wrap",
    minHeight: Platform.OS === "web" ? 100 : 100,
    padding: Platform.OS === "web" ? 20 : 5,
    backgroundColor: "#fff",
  },
  leftSide: {
    flexDirection: Platform.OS === "web" ? "row" : "column",
    alignItems: "center",
  },
  rightSide: {
    flexDirection: Platform.OS === "web" ? "row" : "column",
    alignItems: "center",
  },
  iconSide: {
    flexDirection: Platform.OS === "web" ? "row" : "column",
    alignItems: "center",
  },
  icon: {
    fontSize: Platform.OS === "web" ? 90 : 70,
  },
  buttonContainer: {
    paddingHorizontal: 4,
    alignItems: "flex-start",
  },
  joinContainer: {
    paddingHorizontal: 4,
    paddingVertical: 8,
    backgroundColor: "#000",
    width: 130,
  },
  joinTeamText: {
    fontSize: 13,
    lineHeight: 13,
    fontWeight: "600",
    color: "#fff",
  },
  applyToPressLikeText: {
    fontSize: Platform.OS === "web" ? 20 : 14,
    fontWeight: "700",
    lineHeight: 34,
    fontFamily: "Montserrat-Regular",
  },
  applyTodayButton: {
    fontSize: Platform.OS === "web" ? 24 : 16,
    fontWeight: "600",
    lineHeight: 30,
    fontFamily: "Montserrat-Regular",
  },
  applyTodayButtonText: {
    fontSize: Platform.OS === "web" ? 24 : 16,
    fontWeight: "900",
    color: "#000",
    fontFamily: "Montserrat-Bold",
  },
});
