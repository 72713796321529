import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
  Pressable,
  Linking,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { getAuth, signOut } from "firebase/auth";
import { storage } from "../utils/storage";

const height = Dimensions.get("screen").height;

interface Link {
  text: string;
  url?: string;
  key?: string;
}

const SIDEBAR_LINKS: Link[] = [
  {
    text: "Get started",
    url: "https://www.amazingagent.com/makemillions11",
  },
  {
    text: "All designs",
    key: "All Designs",
  },
  {
    text: "Careers",
    url: "https://www.100kmind.com/careers",
  },
  {
    text: "FAQ",
    key: "FAQ Page",
  },
];

const SIDEBAR_SOCIALS = [
  {
    icon: "facebook", //https://www.facebook.com/PressLikeHQ
    name: "facebook",
  },
  {
    icon: "twitter", //https://twitter.com/i/flow/login?redirect_after_login=%2FPressLikeHQ
    name: "twitter",
  },
  {
    icon: "instagram", //https://www.instagram.com/presslikehq/
    name: "instagram",
  },
  {
    icon: "youtube", //https://www.youtube.com/@presslike4044
    name: "youtube",
  },
  {
    icon: "linkedin", //https://www.linkedin.com/company/press-like-llc/
    name: "linkedin",
  },
];
const Sidebar = ({
  sidebarVisible,
  toggleSidebar,
  navigation,
  isLoggedIn,
}: any) => {

  const [currentLink, setCurrentLink] = useState<string | undefined>(undefined);

  const redirectComponent = () => {
    if (isLoggedIn) {
      // User is logged in, perform logout action
      handleSignOut();
      
    } else {
      // User is not logged in, perform login action
      navigation.navigate("Sign In");
    }
  };

  useEffect(() => {
    if (currentLink === "designs") {
      navigation.navigate("All Designs");
    } else if (currentLink === "All Designs") {
      // Check for "myDesigns" link
      navigation.navigate("All Designs"); // Navigate to "MyDesigns" screen
    } else if (currentLink === "FAQ Page") {
      navigation.navigate("FAQ Page");
    }
    
  }, [currentLink, navigation]);

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        storage.clearAll();
        // Logout successful
        console.log("Logged out");
      })
      .catch((error) => {
        // Handle logout error
        console.log("Logout error:", error);
      });
  };

  const renderDummyLinks = () =>
    SIDEBAR_LINKS.map((link) => (
      <Pressable
        onPress={() => {
          if (link.url) {
            Linking.openURL(link.url);
          } else {
            setCurrentLink(link.key ? link.key : "");
          }
        }}
        style={styles.links}
        key={link.text}
      >
        <Text style={styles.textLink}>{link.text}</Text>
      </Pressable>
    ));

  const renderSocials = () => (
    <View style={styles.socials}>
      {SIDEBAR_SOCIALS.map((social) => (
        <Pressable
          key={social.name}
          onPress={() => {
            switch (social.name) {
              case "facebook":
                Linking.openURL("https://www.facebook.com/PressLikeHQ");
                break;
              case "twitter":
                Linking.openURL(
                  "https://twitter.com/i/flow/login?redirect_after_login=%2FPressLikeHQ"
                );
                break;
              case "instagram":
                Linking.openURL("https://www.instagram.com/presslikehq/");
                break;
              case "youtube":
                Linking.openURL("https://www.youtube.com/@presslike4044");
                break;
              case "linkedin":
                Linking.openURL(
                  "https://www.linkedin.com/company/press-like-llc/"
                );
                break;
              default:
                console.log("Social link not found.");
                break;
            }
          }}
        >
          <Icon name={social.icon} size={22} color="#fff" />
        </Pressable>
      ))}
    </View>
  );

  return (
    <>
      {sidebarVisible && (
        <View style={styles.overlay}>
          <View style={styles.container}>
            <View style={styles.row}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={toggleSidebar}
              >
                <Text style={styles.closeButtonText}>X</Text>
              </TouchableOpacity>
              {isLoggedIn && (
                <TouchableOpacity
                  style={styles.loginButton}
                  onPress={() => navigation.navigate("UserDashboard")}
                >
                  <Text style={styles.loginButtonText}>My Account</Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={styles.loginButton}
                onPress={redirectComponent}
              >
                <Text style={styles.loginButtonText}>
                  {isLoggedIn ? "Sign Out" : "Sign In"}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.line} />
            {renderDummyLinks()}
            {renderSocials()}
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: Platform.OS === "web" ? 400 : 275,
    padding: 10,
    backgroundColor: "rgba( 0, 0, 0, 1)",
    zIndex: 999,
    height: "window",
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  line: {
    height: 1,
    width: Platform.OS === "web" ? 380 : 230,
    margin: "auto",
    backgroundColor: "rgba(97,95,95,1)",
    marginVertical: 20,
  },
  closeButton: {
    marginTop: 5,
    marginLeft: 5,
    marginRight: "auto",
  },
  closeButtonText: {
    fontSize: 22,
    color: "#fff",
    fontWeight: "normal",
    fontFamily: "Montserrat-Regular",
  },
  loginButton: {
    marginTop: 5,
    marginLeft: "auto",
    marginRight: 5,
  },
  loginButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Montserrat-Regular",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
    height: "window",
  },
  textLink: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#fff",
    fontFamily: "Montserrat-Regular",
  },
  links: {
    marginVertical: 18,
  },
  socials: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "rgba(97,95,95,1)",
    borderBottomWidth: 1,
    marginVertical: 15,
    paddingVertical: 15,
    gap: 10,
  },
});

export default Sidebar;
