import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Dimensions, Platform } from "react-native";
import PromoCards from "../components/PromoCards";

function Promotional({ navigation }: any) {
  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const { width } = Dimensions.get("window");
    setIsResponsive(() => Platform.OS !== "web" || width <= 1000);
    const handleWindowResize = () => {
      const { width } = Dimensions.get("window");
      setIsResponsive(() => Platform.OS !== "web" || width <= 1000);
    };
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.leftSide,
          isResponsive && styles.leftSideSmall,
        ]}
      >
        <View style={[styles.mainTitleContainer, isResponsive && { marginBottom: 12 }]}>
          <Text style={styles.requestDesignText}>Request Designs</Text>
          <Text style={styles.freshDesignText}>FRESH DESIGNS EVERY MONTH</Text>
        </View>
        <Text style={styles.descriptionText}>
          Enhance your social media presence effortlessly by accessing our
          design services. Simply log in to your "My Account" portal, choose
          your preferred designs, and follow the intuitive prompts. Our
          dedicated Design Team will handle the rest, ensuring your content
          stands out on social media platforms. Experience the convenience and
          impact of professional design with ease.
        </Text>
      </View>
      <View style={styles.promoCardStyle}>
        <PromoCards navigation={navigation} />
      </View>
    </View>
  );
}

export default Promotional;

const styles = StyleSheet.create({
  container: {
    // justifyContent: "space-evenly",
    // flexDirection: "column",
    backgroundColor: "#fff",
    minHeight: Platform.OS === "web" ? 100 : 100,
    padding: Platform.OS === "web" ? 20 : 5,
    flexDirection: Platform.OS === "web" ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  leftSide: {
    flexDirection: Platform.OS === "web" ? "row" : "column",
    alignItems: Platform.OS === "web" ? "flex-start" : "center",
    paddingHorizontal: -20,
  },
  leftSideSmall: {
    flexDirection: "column",
    alignItems: "center"
  },
  mainTitleContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: "#000",
    minWidth: 310,
    height: 90,
  },

  requestDesignText: {
    fontSize: 32,
    lineHeight: 48,
    fontWeight: "700",
    fontFamily: "Montserrat-Bold",
    color: "#fff",
    alignItems: "flex-start",
  },
  freshDesignText: {
    fontSize: Platform.OS === "web" ? 16 : 14,
    lineHeight: 16,
    fontWeight: "400",
    fontFamily: "Montserrat-Regular",
    color: "#919191",
  },
  descriptionText: {
    fontSize: Platform.OS === "web" ? 16 : 14,
    lineHeight: 24,
    fontWeight: "300",
    fontFamily: "Montserrat-Regular",
    color: "#000",
    paddingHorizontal: 20,
  },
  promoCardStyle: {
    // width: Platform.OS === "web" ? "auto" : "auto",
    // marginHorizontal: "auto",
    alignItems: "center",
    flexDirection: Platform.OS === "web" ? "column" : "row",
    justifyContent: "center",
  },
});
